import './style.css';
import Cover from '../../components/Cover';
import FlipSide from '../../components/FlipSide';
import TileComponent from '../../components/TileComponent';
import ScrollBackground from '../../components/ScrollBackground';



function Home () {

    return(
        <div className='home-screen'>
            <Cover/>    
            <TileComponent/>
            <FlipSide 
                title={'PRÄZISION'}
                imageUrl={'/img/mtflipside1.jpg'} 
                description={'Entdecken Sie die Vorteile von Magic Tech Autopflege, Ihrem Experten für Autoaufbereitung und Fahrzeugpflege. Unser hochqualifiziertes Team legt Wert auf Präzision und Sorgfalt, um Ihr Auto perfekt zu reinigen und zu schützen. Mit hochwertigen Produkten und innovativen Techniken sorgen wir dafür, dass Ihr Fahrzeug in neuem Glanz erstrahlt.Egal, ob es um Lackaufbereitung, Innenreinigung oder Versiegelung geht – wir bieten maßgeschneiderte Lösungen für jedes Auto. Vertrauen Sie auf Magic Tech Autopflege für die beste Pflege Ihres Fahrzeugs. Buchen Sie jetzt einen Termin bei Magic Tech Autopflege in Ternitz, der exklusivsten Autokosmetik der Region und erleben Sie, warum wir die führende Wahl in Ternitz und Umgebung sind!'}
                backgroundColor={'#212429'}
                color={'white'}
            /> 
            <FlipSide 
                title={'ELEGANZ'} 
                imageUrl={'/img/mtflipside5.jpg'} 
                description={'Ein sauberes Auto ist nicht nur ein Zeichen von Eleganz und Stil, sondern vermittelt auch Professionalität und Sorgfalt. Bei Magic Tech Autopflege legen wir Wert auf Perfektion. Mit präziser Lackaufbereitung, umfassender Lederpflege und gründlicher Innenraumreinigung bringen wir Ihr Fahrzeug in Bestform. Unsere Dienstleistungen bei Magic Tech garantieren langfristigen Schutz und makellosen Glanz. Ein gepflegtes Auto hinterlässt einen bleibenden Eindruck – ob auf der Straße oder beim Geschäftstreffen. Buchen Sie jetzt einen Termin bei Magic Tech Autopflege in Ternitz, der exklusivsten Autokosmetik der Region und erleben Sie, wie Ihr Fahrzeug wieder an Eleganz und Wert gewinnt!'}
                backgroundColor={'#212429'}
                color={'white'}
                reverse={true} 
            />
            <FlipSide 
                title={'LEDERPFLEGE'} 
                imageUrl={'/img/mtflipside3.jpg'} 
                description={'Gut gepflegte Lederautositze verleihen Ihrem Fahrzeug nicht nur einen Hauch von Luxus und Eleganz, sondern verlängern auch die Lebensdauer des Leders. Bei Magic Tech Autopflege bieten wir eine präzise Lederpflege und Lederaufbereitung, die Ihre Sitze vor Abnutzung, Rissen und Verfärbungen schützt. Unser Team verwendet hochwertige Produkte und moderne Techniken, um die Schönheit und den Komfort Ihrer Ledersitze wiederherzustellen. Mit unserer professionellen Lederaufbereitung erstrahlt Ihr Auto in neuem Glanz, und das Leder bleibt weich und geschmeidig. Buchen Sie jetzt einen Termin Buchen Sie jetzt einen Termin bei Magic Tech Autopflege in Ternitz, der exklusivsten Autokosmetik der Region und erleben Sie, wie Ihre Ledersitze wieder an Eleganz und Wert gewinnen. Vertrauen Sie auf unsere Expertise für die beste Pflege Ihres Fahrzeugs!'}
                backgroundColor={'#212429'}
                color={'white'}
            /> 
            <ScrollBackground reverse={true}/>
        </div>
    );

};

export default Home;