import './style.scss';

function ScrollBackground ({reverse}) {
    return(
        <section className='scrollbackground-component'>
            <div className='first-layer' style={{backgroundImage:'url(/img/mtscrollbg1.jpg)'}}>
                <h2 className='headline'>
                    1.Bedarfserhebung
                </h2>
                <p className='description'>
                    Die Bedarfsermittlung ist der erste Schritt zu einer perfekten Autopflege. Bei Magic Tech nehmen wir uns die Zeit, Ihre individuellen Wünsche und Bedürfnisse zu verstehen. Ob es um Lederpflege, Politur, Innenreinigung usw. geht – wir erfassen alle Details, um Ihnen ein maßgeschneidertes Angebot zu unterbreiten.
                </p>
            </div>
            <div className='second-layer' style={{backgroundImage:'url(/img/mtscrollbg2.jpg)'}}>
                <h2 className='headline'>
                   2.Angebotserstellung
                </h2>
                <p className='description'>
                    Unsere Angebotserstellung sorgt dafür, dass Sie die beste Autopflege zu fairen Preisen erhalten. Bei Magic Tech analysieren wir Ihre spezifischen Anforderungen und erstellen ein individuelles Angebot für unsere Dienstleistungen.                
                </p>
            </div>
            <div className='third-layer' style={{backgroundImage:'url(/img/mtscrollbg3.jpg)'}}>
                <h2 className='headline'>
                    3.Terminvereinbarung
                </h2>
                <p className='description'>
                    Die Terminvereinbarung für Ihre Autopflege bei Magic Tech ist unkompliziert und effizient! Sie können Ihren Termin ganz einfach per Telefon, E-Mail oder WhatsApp anfragen, um den Prozess so reibungslos wie möglich zu gestalten. Durch unsere flexible Terminvereinbarung garantieren wir, dass Ihr Fahrzeug in kürzester Zeit die optimale Pflege erhält. Vereinbaren Sie jetzt Ihren Termin und profitieren Sie von unserem erstklassigen Service, der Ihr Auto zum Strahlen bringt!               
                </p>
                </div>
            <div className='fourth-layer' style={{backgroundImage:'url(/img/mtscrollbg4.jpg)'}}>
                <h2 className='headline'>
                    4.Durchführung der Autopflege
                </h2>
                <p className='description'>
                Die Durchführung der Autopflege bei Magic Tech in Ternitz ist der entscheidende vierte Schritt, um Ihr Auto in neuem Glanz erstrahlen zu lassen. Mit modernsten Techniken und hochwertigen Produkten bieten wir professionelle Leistung. Unser erfahrenes Team arbeitet mit größter Sorgfalt und Präzision, um den Wert Ihres Fahrzeugs zu steigern.
                </p>
            </div>
            <div className='fifth-layer' style={{backgroundImage:'url(/img/mtscrollbg5.jpg)'}}>
                <h2 className='headline'>
                    5.Fahrzeugübergabe
                </h2>
                <p className='description'>
                Die Fahrzeugübergabe ist der krönende Abschluss unserer umfassenden Autopflege bei Magic Tech in Ternitz. Nachdem wir Ihr Auto professionell gereinigt und gepflegt haben, übergeben wir es Ihnen in makellosem Zustand – bereit, wieder auf die Straße zu gehen. Unser Team erklärt Ihnen die durchgeführten Arbeiten, von der Lederpflege bis zur Lackaufbereitung, und stellt sicher, dass Sie rundum zufrieden sind. Buchen Sie jetzt bei Magic Tech und erleben Sie, wie Ihr Fahrzeug durch unseren erstklassigen Service in neuem Glanz erstrahlt!
                </p>
            </div>
        </section>
    )
}

export default ScrollBackground;