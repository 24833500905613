import GoogleMaps from '../../components/GoogleMaps';
import './style.css';



function Contact (scrollIntoView) {

    return(
        <section className='contact-screen' style={{backgroundImage:'url(/img/mttilebg5.jpg)'}}>
            <div className='container'>
                <h1 className='title'>
                    Kontakt
                </h1>
                <div className='contact-information-row'>
                    <div className='contact-information-box'  style={{backgroundImage:'url(/img/contactbg4.jpg)'}}>
                        <h2 className='headline'>
                            Magic Tech
                        </h2>
                        <p className='contact-information'> 
                            <span>
                                Mobil:
                            </span>
                            <a href='tel:+436645337410'>
                                +43-664-5337410
                            </a>
                        </p>
                        <p className='contact-information'> 
                            <span>
                                E-mail: 
                            </span>
                            <a href='mailto:Magictech1986at@gmail.com'>
                                Magictech1986at@gmail.com
                            </a>
                        </p>
                        <ul class="social-media-links">
                            <li>
                                <a href="https://www.facebook.com/geregurmobilautopflege">
                                    <img src="img/fbicon.png" className='social-icon' alt='magictech-logo'/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/magictech_autopflege/">
                                    <img src="img/instaicon.png" className='social-icon' alt='instaicon'/>
                                </a>
                            </li>
                            <li>
                                <a href="https://wa.me/436645337410">
                                    <img src="img/whatsapplogo.png" className='social-icon' alt='whatsapplogo'/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@magictech.autopfl">
                                    <img src="img/tiktoklogo.png" className='social-icon' alt='tiktoklogo'/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='contact-screen-map'>
                    <div class="map-button">
                        <GoogleMaps/>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Contact;
