import './style.scss'

function TileComponent () {
    return(
        <section className='tile-component' style={{backgroundImage:'url(img/mttilebg.jpg)'}}>
            <div className='container'>
                <h1 className='title'>
                   Warum Magic Tech?
                </h1>
                <p className='headline'>
                    Bei uns erhalten Sie professionelle Autopflege mit modernen Methoden. Langfristiger Schutz, erstklassige Reinigung und perfekte Ergebnisse – für ein Fahrzeug, das dauerhaft glänzt und an Wert gewinnt!
                </p>
                <div className='cards'>
                    <div className='card'>
                        <img className='card-image' alt='card-image' src='/img/mttilebg2.jpg'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                I. Höchste Qualität und Präzision
                            </h3>
                            <p className='card-description'>
                                Bei Magic Tech garantieren wir professionelle Autopflege auf höchstem Niveau. Von der Lederpflege bis zur Keramikversiegelung arbeiten wir mit größter Sorgfalt und modernster Technik.
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <img className='card-image' alt='card-image' src='/img/mttilebg3.jpg'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                               II. Individuelle Lösungen
                            </h3>
                            <p className='card-description'>
                                Unsere maßgeschneiderte Bedarfsermittlung stellt sicher, dass Ihr Fahrzeug genau die Pflege erhält, die es benötigt. Jedes Auto ist einzigartig – und das berücksichtigen wir bei jedem Service.                            
                            </p>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <img className='card-image' alt='card-image' src='/img/mttilebg4.jpg'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                III. Schnelle und flexible Terminvereinbarung
                            </h3>
                            <p className='card-description'>
                                Ob per Telefon, E-Mail oder WhatsApp – bei Magic Tech ist die Terminvereinbarung schnell und unkompliziert, um Ihre Zeit bestmöglich zu nutzen.
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <img className='card-image' alt='card-image' src='/img/mttilebg5.jpg'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                IV. Zufriedenheitsgarantie
                            </h3>
                            <p className='card-description'>
                                Ihre Zufriedenheit hat für uns oberste Priorität. Bei Magic Tech strahlt Ihr Fahrzeug nicht nur äußerlich, sondern Sie werden auch mit dem Service rundum zufrieden sein. Jetzt Termin buchen und den Unterschied erleben!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TileComponent;