import './style.scss';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <section className='notFound' style={{backgroundImage:'url(/img/mttilebg5.jpg)'}}>
            <div className='container'>
                <h1 className='title'>404 - Seite wurde nicht gefunden  :(</h1>
                <p className='description'>Es tut uns leid, die gesuchte Seite existiert nicht.</p>
                <Link to="/" style={{ textDecoration: 'none', color: '#007BFF' }}>
                    <button className='button'>
                        Zurück zum Magic-Tech!
                    </button>
                </Link>
            </div>
        </section>
    );
};

export default NotFound;
