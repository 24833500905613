import React from 'react';
import './style.scss';


const MyMapComponent = () => {
    return (
      <div className='map-container'>
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d957.1249328304052!2d16.00993827942511!3d47.69696309408311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476ddbd6232440d1%3A0x9ae8ec391b822e5e!2sMagic%20Tech%20k%26k%20autopflege!5e0!3m2!1shu!2shu!4v1728402458642!5m2!1shu!2shu"
          style={{ borderRadius: 10 , border: 0}}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    );
  };
  
  export default MyMapComponent;