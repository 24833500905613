import { Link } from 'react-router-dom';
import './style.scss';

function ContentCircles () {
    return(
        <section className='content-circles'>
            <div className='container'>
                <div className='feature-row'>
                    <div className='feature'>
                        <img src='/img/versiegelung.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                            Versiegelung
                        </h2>
                        <p className='price'>
                                Ab 500€
                            </p>
                            <Link to={'/contact'}>
                                <button className='button'>
                                    Kontakt
                                </button>
                            </Link>
                        <p className='description'>                 
                            Durch unsere hochwertige Lackversiegelung wird die Oberfläche Ihres Autos vor Umwelteinflüssen wie Schmutz, UV-Strahlen und Wasserflecken geschützt. Das Ergebnis: eine glänzende, pflegeleichte Karosserie, die ihren Wert lange behält. Lassen Sie Ihr Auto von unseren Experten versiegeln und genießen Sie den langanhaltenden Schutz und die makellose Optik. Vereinbaren Sie jetzt einen Termin für Ihre professionelle Versiegelung und sorgen Sie für den perfekten Schutz Ihres Fahrzeugs!                        
                        </p>
                            
                    </div>
                    <div className='feature best'>
                        <img src='/img/mtlack.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                            Politur
                        </h2>
                        <p className='price'>
                            Ab 250€
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>
                        Die Politur bei Magic Tech verleiht Ihrem Fahrzeug ein frisches, strahlendes Aussehen. Mit unserer professionellen Behandlung werden feine Kratzer und matte Stellen schonend entfernt, was den Lack langlebig schützt und gleichzeitig beeindruckenden Glanz erzeugt. Dadurch bleibt Ihr Auto nicht nur länger sauber, sondern gewinnt auch an Wert. Vertrauen Sie auf unsere Expertise und gönnen Sie Ihrem Fahrzeug eine erstklassige Politur. Buchen Sie jetzt und erleben Sie den Unterschied!                        
                        </p>                        
                    </div>
                    <div className='feature'>
                        <img src='/img/mtinnenraum2.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                            Innenraumreinigung
                        </h2>
                        <p className='price'>
                            Ab 150€
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>
                            Bei der gründlichen Innenraumreinigung entfernen wir jeglichen Schmutz aus dem Innenraum Ihres Fahrzeugs. Polster, Teppiche und Oberflächen werden sauber und frisch hinterlassen, sodass Ihr Auto innen wie neu erstrahlt. Für ein genaues Angebot bitten wir Sie, uns zu kontaktieren!
                        </p>
                    </div>
                </div>
                <div className='feature-row'>
                    <div className='feature'>
                        
                        <img src='/img/mtschnellpackung.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                           Innen- + Außenreinigung
                        </h2>
                        <p className='price'>
                            Ab 200€
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>                 
                        Entscheiden Sie sich für unser umfassendes Servicepaket bei Magic Tech in Ternitz! Unsere Innen- und Außenreinigung beinhaltet eine gründliche Dampfreinigung, die Schmutz, Bakterien und unangenehme Gerüche effektiv entfernt, sodass Ihr Fahrzeug nicht nur hygienisch sauber, sondern auch frisch riechend ist. Für ein genaues Angebot bitten wir Sie, uns zu kontaktieren!
                        </p>
                        
                    </div>
                    <div className='feature best'>
                        <img src='/img/mtnano3.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                        Innen- + Außenreinigung + Glanzpolitur
                        </h2>
                        <p className='price'>
                            Ab 300€
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>
                            Unser beliebtestes Servicepaket von Magic Tech bietet Ihrem Auto eine Rundum-Pflege für strahlende Sauberkeit. Mit unserer Innenreinigung entfernen wir hartnäckigen Schmutz und Staub aus dem Innenraum, während die Außenreinigung für ein makelloses Äußeres sorgt. Zum krönenden Abschluss verleiht die Glanzpolitur Ihrem Fahrzeug einen tiefen Glanz und langanhaltenden Schutz. Perfekt für alle, die den Wert ihres Autos erhalten und gleichzeitig für einen glänzenden Auftritt sorgen möchten. Vereinbaren Sie jetzt Ihren Termin und bringen Sie den Glanz zurück auf die Straße!                        
                        </p>
                    </div>
                    <div className='feature'>
                        <img src='/img/mtglass.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                            Windschutzreparatur
                        </h2>
                        <p className='price'>
                            Ab 80€
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>
                            Wir bieten Ihnen eine zuverlässige Windschutzreparatur, die Ihre Fahrsicherheit garantiert. Unsere erfahrenen Techniker beseitigen schnell und effektiv Risse und Chips in Ihrem Windschutzscheibe, bevor sie zu größeren Problemen führen. Durch unsere hochwertigen Materialien und modernen Techniken stellen wir sicher, dass Ihre Scheibe schnell wieder in Top-Zustand ist. Warten Sie nicht, bis es zu spät ist – schützen Sie sich und Ihre Mitfahrer! Kontaktieren Sie uns noch heute für eine individuelle Beratung und ein unverbindliches Angebot!
                        </p>
                    </div>
                </div>
                <div className='feature-row'>
                    <div className='feature'>
                        <img src='/img/mtdampf2.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                           Schnellpackung
                        </h2>
                        <p className='price'>
                            Ab 99€
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>                 
                        Die MagicTech Schnellpackung ist die ideale Lösung für eine schnelle und gründliche Innen- und Außenreinigung Ihres Fahrzeugs. Unser Service bietet eine effiziente Pflege, die Ihr Auto wieder in neuem Glanz erstrahlen lässt. Bitte beachten Sie, dass die Schnellpackung je nach Größe und Verschmutzungsgrad des Fahrzeugs verfügbar ist. So stellen wir sicher, dass jeder Kunde die bestmögliche Reinigung erhält. Achtung, dieses Paket enthält keine Tiefenreinigung. Kontaktieren Sie uns noch heute, um herauszufinden, ob Ihr Fahrzeug für unsere Schnellpackung geeignet ist, und genießen Sie eine blitzschnelle Autopflege!
                        </p>                       
                    </div>
                    <div className='feature best'>
                        <img src='/img/mtflipside3.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                            Lederreparatur
                        </h2>
                        <p className='price' style={{fontSize:'18px', marginBottom:'25px'}}>
                            Der Preis hängt vom Zustand ab.
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>
                            Erfrischen Sie das Innere Ihres Fahrzeugs mit dem professionellen Lederbezüge-Renovierungsservice von Magic Tech! In Ternitz, der exklusivsten Autopflege, verwenden unsere Experten die besten Materialien, um alte Sitze wieder elegant und komfortabel zu machen. Die Renovierung verbessert nicht nur das Erscheinungsbild, sondern steigert auch den Wert Ihres Autos. Wählen Sie Nachhaltigkeit und geben Sie Ihren Lederbezügen neues Leben! Kontaktieren Sie uns noch heute für eine kostenlose Beratung! Für ein genaues Angebot bitten wir Sie, uns zu kontaktieren!
                        </p>                     
                    </div>
                    <div className='feature'>
                        <img src='/img/mtinnenraum.jpg' alt='' className='image'/>
                        <h2 className='headline'>
                            Sitze
                        </h2>
                        <p className='price'>
                            Ab 20€/Sitze
                        </p>
                        <Link to={'/contact'}>
                            <button className='button'>
                                Kontakt
                            </button>
                        </Link>
                        <p className='description'>
                            Suchen Sie nach einer zuverlässigen Sitzbezüge Reinigung? Bei Magic Tech in Ternitz bieten wir eine umfassende und professionelle Autoinnenreinigung, die den Komfort und die Ästhetik Ihres Fahrzeugs deutlich verbessert. Unsere Fachleute verwenden moderne Techniken und hochwertige Produkte, um Schmutz und Flecken effektiv zu entfernen und Ihren Sitzen neues Leben einzuhauchen. Schützen Sie das Material und erhöhen Sie den Wert Ihres Fahrzeugs! Vertrauen Sie auf unsere Expertise für eine makellose Innenausstattung. Jetzt anfragen für ein kostenloses Angebot! Für ein genaues Angebot bitten wir Sie, uns zu kontaktieren!                        
                        </p>                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentCircles;