import './style.scss';
import { useState, useEffect } from 'react';
import { FaGift, FaCar, FaTree, FaSmile, FaCarAlt } from "react-icons/fa";
import { FaArrowRight, FaCarOn } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

function PopUp() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="popup-overlay">
          <div className="popup" style={{backgroundImage:'url(/img/gutschein.jpg)'}}>
            <button className="popup-button" onClick={handleClose}>
              ✖
            </button>
            <div className="popup-content">
              <h2 className="popup-title">🎄 Verschenke Glanz zu Weihnachten! 🎄</h2>
              <p className="popup-headline">
                Mit einem Gutschein für professionelle Autopflege! 🚗🎁
              </p>
              <ul className="popup-list">
                <li>
                  <FaCarOn className="popup-icon" size={48} fill='red'/> Strahlender Lack, sauberes Interieur – ein Geschenk, das garantiert Freude macht.
                </li>
                <li>
                  <FaCarOn className="popup-icon" size={30} fill='white'/> Für jede Leistung einlösbar! 
                </li>
                <li>
                  <FaCarOn className="popup-icon" size={30} fill='white'/> Stilvoll verpackt, bereit für den Weihnachtsbaum.
                </li>
                <li>
                  <FaCarOn className="popup-icon" size={30} fill='white'/> Perfekt für Freunde, Familie oder dich selbst.
                </li>
              </ul>
              <p className="popup-cta">🎁 Jetzt sichern und Weihnachtsglanz verschenken!</p>
              <p className="popup-contact">Kontaktiere uns direkt oder besuche uns vor Ort.</p>
              <Link to={"/contact"}>
                <button className='button'> Kontakt </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopUp;
