import './App.css'
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import  Home  from './screens/Home';
import DefaultLayout from './layouts/DefaultLayout';
import AboutUs from './screens/AboutUs';
import Contact from './screens/Contact';
import ScrollTop from './components/ScrollTop';
import Services from './screens/Services';
import NotFound from './components/NotFound';



function App() {


  return (
    <BrowserRouter>
    <ScrollTop/>
      <Routes>
        <Route element={ <DefaultLayout/> }>
          <Route index element={<Home/>}/>
          <Route path={'about-us'} element={ <AboutUs/> } />
          <Route path={'contact'} element={ <Contact/> } />
          <Route path={'services'} element={<Services/>} />
          <Route path={'*'} element={<NotFound/>} />
        </Route>
      </Routes>
    </BrowserRouter>
    
  );
}


export default App;

