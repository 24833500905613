import ContentCircles from '../../components/ContentCircles';
import './style.scss';


function Services () {
    return(
        <section className='service-screen'>
            <h1 className='title'> DIENSTLEISTUNGEN </h1>
            <ContentCircles/>
        </section>
    )
}

export default Services;