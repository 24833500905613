import './style.scss';

function AboutUs (scrollToFlipSide) {
    return(
        <section className='screen-aboutus' style={{backgroundImage:'url(/img/mttilebg4.jpg)'}}>
            <div className='container'>
                <article className='main-article'>
                    <h1 className='title'>
                        ÜBER UNS
                    </h1>
                    <div className='description'>
                        <p>
                        Magic Tech Autokosmetik in Ternitz, geleitet von Fülöp Ádám, bietet erstklassige Autopflege, die Ihr Fahrzeug wie neu erscheinen lässt. Unsere Dienstleistungen umfassen Lackversiegelung, Innenraumreinigung, Politur und vieles mehr. Mit modernster Technik und hochwertigen Produkten garantieren wir den besten Schutz und Glanz für Ihr Auto. Wenn Sie nach professioneller Autopflege in Ternitz oder Autoreinigung in der Nähe suchen, sind Sie bei uns genau richtig. Besuchen Sie uns und überzeugen Sie sich von unserem erstklassigen Service!
                        </p>
                    </div>
                </article>
            </div>
        </section>
    )
}

export default AboutUs;