import { useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

function Navigation (scrollIntoView) {

    const navigationItems = [

        { id:'cover', label: 'HOME', path: '/'},
        { id:'about-us', label: 'ÜBER UNS', path: '/about-us'},
        { id:'services', label: 'DIENSTLEISTUNGEN', path: '/services'},
        { id:'contact', label: 'KONTAKT', path: '/contact'},
    ];

    /* hozz létre egy javascript funckiót onNavigationToggle néven és 
        azt írja a console-be hogy "button clicked"
        a funkció akkor legyen meghívva ha a navigation toggler gombra kattint a felhasználó
    */

    const onNavigationToggle = () => {
        setIsOpened( isOpened ? false : true);
    }


    /*
        hozz létre egy állapottal rendelkező változót a useState segítségével
        a változó neve az legyen hogy isOpened 
        azt a funkciót ami beállítja az isOpened értékét pedig setIsOpened -nek hívjuk
        az isOpened alapértéke pedig false.
    */
    const [isOpened, setIsOpened] = useState(false);

    const handleClick = () => {
        scrollToFlipSide();
        setIsOpened(false);
    }

    let scrollToFlipSide = () => {
        document.getElementById("cover").scrollIntoView({behavior:'smooth'});
        console.log('mukszik');
    }


    return (

        <nav className='main-navigation'>
            <Link to={'/'}>
                <div className='main-navigation-img'>
                    <img src='/img/mtlogo2.webp' height={85} width={90} alt='magic tech logo'/>
                </div>
            </Link>
            <ul className='main-navigation-links' style={{display: isOpened ? 'flex' : 'none'}}>
                { navigationItems.map((navigationItem) => 
                    <li key={navigationItem.id} className='navigation-link-buttons'>
                        <Link to={navigationItem.path} onClick={() => handleClick()}>
                                {navigationItem.label}
                        </Link>
                    </li>
                )}
            </ul>
            <button className='mobile-toggler' onClick={() => onNavigationToggle()}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </nav>
        
    );
};

export default Navigation;